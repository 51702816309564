<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>
		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	import	WithLeftMenu from '@/components/app/WithLeftMenu'

	import 	projectsData from "@/assets/json/projects.json";
	import 	featuresData from "@/assets/json/features.json";

	export default {
		metaInfo: {
			title: 'Создание интернет-магазина под ключ, цена разработки | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Закажите разработку эффективного интернет-магазина, в котором покупают! Этапы создания, стоимость разработки и гарантии. Заполните форму на сайте, и мы вам перезвоним!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Создание интернет-магазина под ключ, цена разработки | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Закажите разработку эффективного интернет-магазина, в котором покупают! Этапы создания, стоимость разработки и гарантии. Заполните форму на сайте, и мы вам перезвоним!' },
			],
		},
		data: () => ({
			withLeftMenuContent: [
				{
					component: 'projects',
					name: 'Примеры работ',
					quantity: 3,
					quantityTable: 3,
					quantityMobile: 3,
					showPagination: false,
					data: projectsData,
					category: "Интернет-магазин"
				},{
					component: 'advantages',
					name: 'Этапы создания',
					columns: 3,
					sliderInMobile: true,
					data: [
						{
							title: 'Анализ рынка',
							text: 'Брифинг заказчика. Постановка целей будущего сайта.<br> Изучение конкурентов, целевой аудитории.<br> Выявление УТП.',
							bottomText: 'Срок - 6 дней'
						},{
							title: 'Проектирование и дизайн',
							text: 'Формирование структуры, кликабельных прототипов. Подбор цвета и стилистики. Разработка дизайн-макетов в трех разрешениях: десктоп, планшет, мобильник.',
							bottomText: 'Срок - 30 дней'
						},{
							title: 'Верстка и программирование',
							text: 'Адаптивная верстка. Настройка CMS Bitrix. Программирование функционала.',
							bottomText: 'Срок - 40 дней'
						},{
							title: 'Интеграции с внешними системами',
							text: 'Синхронизация номенклатуры, остатков и заказов с 1С. Добавление модулей платежей и доставки.',
							bottomText: 'Срок - 5 дней'
						},{
							title: 'Наполнение и SEO-оптимизация',
							text: 'Написание и заполнение контента. Размещение картинок и иконок. Базовая SEO-оптимизация.',
							bottomText: 'Срок - 5 дней'
						},{
							title: 'Запуск и тестирование',
							text: 'Проверка перед выводом. Перенос на хостинг. Привязка домена.',
							bottomText: 'Срок - 4 дня'
						}
					]
				},{
					component: 'slider',
					name: 'Стоимость и гарантии',
					contentType: 'infoBlocks2',
					data: [
						{
							value: '600000',
							label: '600 - 800 т.р.',
							firstTitle: 'Бюджет: 600 – 800 тыс. руб.',
							firstText: 'Каталог до 10 000 позиций, со стандартным набором: фильтрация, поиск, корзина, оформление покупки, онлайн оплата, доставка, личный кабинет покупателя.<br><br> Подходит бизнесу с небольшим товарооборотом.',
							secondTitle: 'Гарантии',
							secondText: 'Бессрочная гарантия на созданный интернет-магазин. Дальнейшее сопровождение и доработки сайта по цене 1 800 руб. за норма-час.'
						},{
							value: '800000',
							label: '800 - 1.1 млн р.',
							firstTitle: 'Бюджет: 800 – 1.1 млн руб.',
							firstText: 'Каталог до 100 000 позиций. Большое количество параметров для фильтрации. Поиск по каталогу со сложной логикой (по корню, по частичному совпадению, по части слова). Корзина, несколько видов онлайн-оплаты и доставки.<br><br> Подходит среднему бизнесу со стабильным товарооборотом.',
							secondTitle: 'Гарантии',
							secondText: 'Бессрочная гарантия на созданный интернет-магазин. Дальнейшее сопровождение и доработки сайта по цене 1 800 руб. за норма-час.'
						},{
							value: '1100000',
							label: '1.1 - 1.4 млн р.',
							firstTitle: 'Бюджет: 1.1 млн руб. – 1.4 млн руб.',
							firstText: 'Каталог до 1 000 000 позиций. Множество интеграций с сервисами. Каталог со сложным функционалом. Бонусные системы, накопительные скидки, комплекты товаров. Расширенный личный кабинет покупателя.<br><br> Подходит крупным игрокам, с высоким товарооборотом.',
							secondTitle: 'Гарантии',
							secondText: 'Бессрочная гарантия на созданный интернет-магазин. Дальнейшее сопровождение и доработки сайта по цене 1 800 руб. за норма-час.'
						},{
							value: '1400000',
							label: '1.4 млн р. и выше',
							firstTitle: 'Бюджет: 1.4 млн. руб. и выше',
							firstText: 'Высоконагруженные интернет-магазины с нестандартным функционалом.<br><br> Подходит федеральным сетям.',
							secondTitle: 'Гарантии',
							secondText: 'Бессрочная гарантия на созданный интернет-магазин. Дальнейшее сопровождение и доработки сайта по цене 1 800 руб. за норма-час.'
						}
					]
				},{
					component: 'aboutUs',
					name: 'Наш выбор CMS',
					data: [
						{
							component: 'image',
							image: require(`@/assets/img/cms.jpg`),
							additionalClass: 'margin7'
						},{
							component: 'text',
							text: `1С-Битрикс — система управления сайтом №1 в России <a href="https://ratingruneta.ru/cms/" target="_blank" rel="nofollow" class="redText">согласно рейтингу Рунета</a>. CMS позволяет разработчикам создавать функционал любой сложности, а владельцам — управлять проектом без помощи программистов.<br><br> С 2017 года сохраняем статус <a href="https://www.1c-bitrix.ru/partners/570583.php" target="_blank" rel="nofollow" class="redText">Золотого сертифицированного партнера</a>. Это значит, что разработчики работают по стандартам Битрикса - страницы сайтов загружаются быстро, отсутствуют баги. Перед сдачей заказчику, проект проверяется <a href="https://dev.1c-bitrix.ru/learning/course/index.php?COURSE_ID=43&CHAPTER_ID=04830" target="_blank" rel="nofollow" class="redText">монитором качества</a>.`,
						}
					]
				},{
					component: 'features',
					name: 'Особенности создания сайтов в Артрокетс',
					data: featuresData
				},{
					component: 'advantages',
					name: 'После сдачи проекта',
					columns: 2,
					data: [
						{
							title: 'Поисковое продвижение',
							text: 'Увеличиваем ежемесячную посещаемость ресурса и улучшаем конверсию.',
						},{
							title: 'Техническая поддержка',
							text: 'Дорабатываем и сопровождаем проекты в режиме 24/7.',
							link: '/uslugi/support/'
						}
					]
				}
			]
		}),
		name: 'internet-magazin',
		components: {
			Feedback: () => import('@/components/new/Forms/Feedback'),
			WithLeftMenu
		}
	}
</script>
